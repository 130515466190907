import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";
import { SummitRegistrationPageQuery } from "../../../graphql-types";
import HeroBanner from "../../components/HeroBanner";
import MenuBar from "../../components/MenuBar";

const RegistrationPage = () => {

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Summit Registration</title>
        <script async src="https://web.cvent.com/event_guest/v1/embed/de7a8525-4212-4a11-879f-0d3c7560f205.js"></script>
      </Helmet>
      <div className="h-screen">
      <div data-cvt-embed></div>

      </div>
    </>
  )
}

export default RegistrationPage;
